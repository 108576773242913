import {
  GET_CUSTOMER_DATA,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_CUSTOMER_DATA_SUCCESS,
  DELETE_CUSTOMER_DATA_FAIL,
  DELETE_CUSTOMER_DATA_SUCCESS_MULTIPLE,
  DELETE_CUSTOMER_DATA_FAIL_MULTIPLE,
  ADD_CUSTOMER_DATA_SUCCESS,
  ADD_CUSTOMER_DATA_FAIL,
  UPDATE_CUSTOMER_DATA_SUCCESS,
  UPDATE_CUSTOMER_DATA_FAIL
} from "./actionType";

const INIT_STATE = {
  customerData: [],
  error: {}
};

const Customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CUSTOMER_DATA:
          return {
            ...state,
            customerData: action.payload.data,
            isCustomerCreated: false,
            isCustomerSuccess: true
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_CUSTOMER_DATA:
          return {
            ...state,
            error: action.payload.error,
            isCustomerCreated: false,
            isCustomerSuccess: false
          };
        default:
          return { ...state };
      }
    case DELETE_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        customerData: state.customerData.filter(
          (customer) => customer._id.toString() !== action.payload.id.toString()
        )
      };
    case DELETE_CUSTOMER_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case DELETE_CUSTOMER_DATA_SUCCESS_MULTIPLE:
      return {
        ...state,
        customerData: state.customerData.filter(
          (customer) => customer._id.toString() !== action.payload.id.toString()
        )
      };
    case DELETE_CUSTOMER_DATA_FAIL_MULTIPLE:
      return {
        ...state,
        error: action.payload
      };
    case ADD_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        customerData: [...state.customerData, action.payload]
      };
    case ADD_CUSTOMER_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case UPDATE_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        customerData: state.customerData.map((customer) =>
          customer._id.toString() === action.payload.data._id.toString()
            ? { ...customer, ...action.payload.data }
            : customer
        )
      };
    case UPDATE_CUSTOMER_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export default Customer;
