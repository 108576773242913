import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Crypto Redux States
import {
  GET_SII_DATA,
  ADD_SII_DATA,
  DELETE_SII_DATA,

  UPDATE_SII_DATA
} from "./actionType";
import {
  siiApiResponseSuccess,
  siiApiResponseError,
  addSiiDataSuccess,
  addSiiDataFail,
  updateSiiDataSuccess,
  updateSiiDataFail,
  deleteSiiDataSuccess,
  deleteSiiDataFail,

} from "./action";

//Include Both Helper File with needed methods
import {
  getSiiData as getSiiDataApi,
  addSiiData,
  updateSiiData,
  deleteSiiData,

} from "../../helpers/backend_helper";

function* getSiiData() {
  try {
    const response = yield call(getSiiDataApi);
    yield put(siiApiResponseSuccess(GET_SII_DATA, response));
  } catch (error) {
    yield put(siiApiResponseError(GET_SII_DATA, error));
  }
}

function* onAddSiiData({ payload: data }) {

  try {
    const response = yield call(addSiiData, data);
    yield put(addSiiDataSuccess(response));
    toast.success("Usuario Agregado satisfactoriamente", { autoClose: 3000 });
  } catch (error) {
    yield put(addSiiDataFail(error));
    toast.error("Error al agregar usuario", { autoClose: 3000 });
  }
}

function* onUpdateSiiData({ payload: data }) {
  try {
    const response = yield call(updateSiiData, data);
    yield put(updateSiiDataSuccess(response));
    toast.success("Usuario actualizado satisfactoriamente", { autoClose: 3000 });
  } catch (error) {
    yield put(updateSiiDataFail(error));
    toast.error("Error al Actualizar usuario", { autoClose: 3000 });
  }
}

function* onDeleteSiiData({ payload: data }) {
  try {
    const response = yield call(deleteSiiData, data);
    yield put(deleteSiiDataSuccess(response));
    toast.success("Usuario Eliminado satisfactoriamente", {
      autoClose: 3000
    });
  } catch (error) {
    yield put(deleteSiiDataFail(error));
    toast.error("Error al Eliminar usuario", { autoClose: 3000 });
  }
}

export function* watchGetSiiData() {
  yield takeEvery(GET_SII_DATA, getSiiData);
}

export function* watchAddSiiData() {
  yield takeEvery(ADD_SII_DATA, onAddSiiData);
}

export function* watchUpdateSiiData() {
  yield takeEvery(UPDATE_SII_DATA, onUpdateSiiData);
}

export function* watchDeleteSiiData() {
  yield takeEvery(DELETE_SII_DATA, onDeleteSiiData);
}


function* siiSaga() {
  yield all([
    fork(watchGetSiiData),
    fork(watchAddSiiData),
    fork(watchUpdateSiiData),
    fork(watchDeleteSiiData),
  ]);
}

export default siiSaga;
