import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { postLogin } from "../../../helpers/backend_helper";


function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_API_URL) {

      const data = {
        username: "antonio",
        email: user.email,
        password: user.password
      };
      const response = yield call(postLogin, data);

      sessionStorage.setItem("authUser", JSON.stringify(response));
      if (response.user) {
        yield put(loginSuccess(response));
        history.push("/dashboard");
      } else {
        yield put(apiError(response.description));
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem("authUser");
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
