import {
  GET_SII_DATA,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_SII_DATA,
  DELETE_SII_DATA_SUCCESS,
  DELETE_SII_DATA_FAIL,
  ADD_SII_DATA,
  ADD_SII_DATA_SUCCESS,
  ADD_SII_DATA_FAIL,
  UPDATE_SII_DATA,
  UPDATE_SII_DATA_SUCCESS,
  UPDATE_SII_DATA_FAIL,
} from "./actionType";

// common success
export const siiApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data }
});
// common error
export const siiApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error }
});

export const getSiiData = () => ({
  type: GET_SII_DATA
});

export const deleteSiiData = (data) => ({
  type: DELETE_SII_DATA,
  payload: data
});

export const deleteSiiDataSuccess = (data) => ({
  type: DELETE_SII_DATA_SUCCESS,
  payload: data
});

export const deleteSiiDataFail = (error) => ({
  type: DELETE_SII_DATA_FAIL,
  payload: error
});


export const addSiiData = (data) => ({

  type: ADD_SII_DATA,
  payload: data
});

export const addSiiDataSuccess = (project) => ({
  type: ADD_SII_DATA_SUCCESS,
  payload: project
});

export const addSiiDataFail = (error) => ({
  type: ADD_SII_DATA_FAIL,
  payload: error
});

export const updateSiiData = (project) => ({
  type: UPDATE_SII_DATA,
  payload: project
});

export const updateSiiDataSuccess = (project) => ({
  type: UPDATE_SII_DATA_SUCCESS,
  payload: project
});

export const updateSiiDataFail = (error) => ({
  type: UPDATE_SII_DATA_FAIL,
  payload: error
});
