import {
  GET_PREVIRED_DATA_CHECK,
  GET_PREVIRED_DATA_ERRORS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_PREVIRED_DATA,
  DELETE_PREVIRED_DATA_SUCCESS,
  DELETE_PREVIRED_DATA_FAIL,
  DELETE_PREVIRED_DATA_SUCCESS_MULTIPLE,
  DELETE_PREVIRED_DATA_FAIL_MULTIPLE,
  ADD_PREVIRED_DATA,
  ADD_PREVIRED_DATA_SUCCESS,
  ADD_PREVIRED_DATA_FAIL,
  UPDATE_PREVIRED_DATA,
  UPDATE_PREVIRED_DATA_SUCCESS,
  UPDATE_PREVIRED_DATA_FAIL,
  DELETE_PREVIRED_DATA_MULTIPLE
} from "./actionType";

// common success
export const previredApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data }
});
// common error
export const previredApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error }
});

export const getPreviredDataCheck = () => ({
  type: GET_PREVIRED_DATA_CHECK
});
export const getPreviredDataError = () => ({
  type: GET_PREVIRED_DATA_ERRORS
});

export const deletePreviredData = (data) => ({
  type: DELETE_PREVIRED_DATA,
  payload: data
});
export const deletePreviredDataMultiple = (data) => ({
  type: DELETE_PREVIRED_DATA_MULTIPLE,
  payload: data
});

export const deletePreviredDataSuccess = (data) => ({
  type: DELETE_PREVIRED_DATA_SUCCESS,
  payload: data
});

export const deletePreviredDataFail = (error) => ({
  type: DELETE_PREVIRED_DATA_FAIL,
  payload: error
});
export const deletePreviredDataSuccessMultiple = (data) => ({
  type: DELETE_PREVIRED_DATA_SUCCESS_MULTIPLE,
  payload: data
});

export const deletePreviredDataFailMultiple = (error) => ({
  type: DELETE_PREVIRED_DATA_FAIL_MULTIPLE,
  payload: error
});

export const addPreviredData = (data) => ({
  type: ADD_PREVIRED_DATA,
  payload: data
});

export const addPreviredDataSuccess = (project) => ({
  type: ADD_PREVIRED_DATA_SUCCESS,
  payload: project
});

export const addPreviredDataFail = (error) => ({
  type: ADD_PREVIRED_DATA_FAIL,
  payload: error
});

export const updatePreviredData = (project) => ({
  type: UPDATE_PREVIRED_DATA,
  payload: project
});

export const updatePreviredDataSuccess = (project) => ({
  type: UPDATE_PREVIRED_DATA_SUCCESS,
  payload: project
});

export const updatePreviredDataFail = (error) => ({
  type: UPDATE_PREVIRED_DATA_FAIL,
  payload: error
});
