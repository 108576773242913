import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Crypto Redux States
import {
  GET_PREVIRED_DATA_CHECK,
  GET_PREVIRED_DATA_ERRORS,
  ADD_PREVIRED_DATA,
  DELETE_PREVIRED_DATA,
  DELETE_PREVIRED_DATA_MULTIPLE,
  UPDATE_PREVIRED_DATA
} from "./actionType";
import {
  previredApiResponseSuccess,
  previredApiResponseError,
  // addPreviredDataSuccess,
  // addPreviredDataFail,
  updatePreviredDataSuccess,
  updatePreviredDataFail,
  // deletePreviredDataSuccess,
  // deletePreviredDataFail,
  // deletePreviredDataSuccessMultiple,
  // deletePreviredDataFailMultiple
} from "./action";

//Include Both Helper File with needed methods
import {
  getPreviredDataCheck as getPreviredDataCheckApi,
  getPreviredDataErrors as getPreviredDataErrorApi,
  addPreviredData,
  updatePreviredData,
  deletePreviredData,
  deletePreviredDataMultiple
} from "../../helpers/backend_helper";

function* getPreviredData() {
  try {
    const response = yield call(getPreviredDataCheckApi);

    yield put(previredApiResponseSuccess(GET_PREVIRED_DATA_CHECK, response));
  } catch (error) {
    yield put(previredApiResponseError(GET_PREVIRED_DATA_CHECK, error));
  }
}
function* getPreviredDataErrors() {
  try {
    const response = yield call(getPreviredDataErrorApi);

    yield put(previredApiResponseSuccess(GET_PREVIRED_DATA_ERRORS, response));
  } catch (error) {
    yield put(previredApiResponseError(GET_PREVIRED_DATA_ERRORS, error));
  }
}

// function* onAddPreviredData({ payload: data }) {
//   try {
//     const response = yield call(addPreviredData, data);
//     yield put(addPreviredDataSuccess(response));
//     toast.success("Cliente agregado satisfactoriamente", { autoClose: 3000 });
//   } catch (error) {
//     yield put(addPreviredDataFail(error));
//     toast.error("Error al agregar cliente", { autoClose: 3000 });
//   }
// }

function* onUpdatePreviredData({ payload: data }) {
  try {
    const response = yield call(updatePreviredData, data);
    yield put(updatePreviredDataSuccess(response));
    toast.success("Cliente modificado satisfactoriamente", { autoClose: 3000 });
  } catch (error) {
    yield put(updatePreviredDataFail(error));
    toast.error("Error al agregar cliente", { autoClose: 3000 });
  }
}

// function* onDeletePreviredData({ payload: data }) {
//   try {
//     const response = yield call(deletePreviredData, data);
//     yield put(deletePreviredDataSuccess(response));
//     toast.success("Cliente Eliminado Satisfactoriamente", { autoClose: 3000 });
//   } catch (error) {
//     yield put(deletePreviredDataFail(error));
//     toast.error("Error al eliminar cliente", { autoClose: 3000 });
//   }
// }
// function* onDeletePreviredDataMultiple({ payload: data }) {
//   console.log("data: ", data);
//   try {
//     const response = yield call(deletePreviredDataMultiple, data);
//     yield put(deletePreviredDataSuccessMultiple(response));
//     toast.success("Previred Data Delete Successfully", { autoClose: 3000 });
//   } catch (error) {
//     yield put(deletePreviredDataFailMultiple(error));
//     toast.error("Previred Data Delete Failed", { autoClose: 3000 });
//   }
// }

export function* watchGetPreviredData() {
  yield takeEvery(GET_PREVIRED_DATA_CHECK, getPreviredData);
}
export function* watchGetPreviredDataError() {
  yield takeEvery(GET_PREVIRED_DATA_ERRORS, getPreviredDataErrors);
}

// export function* watchAddPreviredData() {
//   yield takeEvery(ADD_PREVIRED_DATA, onAddPreviredData);
// }

export function* watchUpdatePreviredData() {
  yield takeEvery(UPDATE_PREVIRED_DATA, onUpdatePreviredData);
}

// export function* watchDeletePreviredData() {
//   yield takeEvery(DELETE_PREVIRED_DATA, onDeletePreviredData);
// }
// export function* watchDeletePreviredDataMultiple() {
//   yield takeEvery(DELETE_PREVIRED_DATA_MULTIPLE, onDeletePreviredDataMultiple);
// }

function* previredSaga() {
  yield all([
    fork(watchGetPreviredData),
    fork(watchGetPreviredDataError),
    // fork(watchAddPreviredData),
    fork(watchUpdatePreviredData),
    // fork(watchDeletePreviredData),
    // fork(watchDeletePreviredDataMultiple)
  ]);
}

export default previredSaga;
