//REGISTER
export const POST_REGISTER = "/auth/register";

//LOGIN
export const POST_LOGIN = "/auth/login";

//PROFILE
export const POST_EDIT_PROFILE = "/user";

//Mail
export const SEND_EMAIL = "/email";

// Pages > Client
export const GET_CUSTOMERDATA = "/cliente";
export const ADD_NEW_CUSTOMERDATA = "/customer";
export const UPDATE_CUSTOMERDATA = "/customer";
export const DELETE_CUSTOMERDATA = "/customer";
export const DELETE_CUSTOMERDATA_MULTIPLE = "/customer";
// Pages > Previred
export const GET_PREVIREDDATA_CHECK = "/previred/revision";
export const GET_PREVIREDDATA_ERRORS = "/previred/error";
export const ADD_NEW_PREVIREDDATA = "/previred";
export const UPDATE_PREVIREDDATA = "/previred";
export const DELETE_PREVIREDDATA = "/previred";
export const DELETE_PREVIREDDATA_MULTIPLE = "/previred";

// Pages > User
export const GET_USERDATA = "/user";
export const DELETE_USERDATA = "/user";
export const ADD_NEW_USERDATA = "/auth/register";
export const UPDATE_USERDATA = "/user";

// Pages > Sii
export const GET_SIIDATA = "/sii";
export const DELETE_SIIDATA = "/sii";
export const ADD_NEW_SIIDATA = "/auth/register";
export const UPDATE_SIIDATA = "/sii";

// File Manager
// Folder
export const GET_FOLDERS = "/folder";
export const DELETE_FOLDER = "/delete/folder";
export const ADD_NEW_FOLDER = "/add/folder";
export const UPDATE_FOLDER = "/update/folder";

// File
export const GET_FILES = "/file";
export const DELETE_FILE = "/delete/file";
export const ADD_NEW_FILE = "/add/file";
export const UPDATE_FILE = "/update/file";
