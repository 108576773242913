// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PREVIRED_DATA_CHECK = "GET_PREVIRED_DATA_CHECK";
export const GET_PREVIRED_DATA_ERRORS = "GET_PREVIRED_DATA_CHECK_ERRORS";
export const DELETE_PREVIRED_DATA = "DELETE_PREVIRED_DATA";
export const DELETE_PREVIRED_DATA_MULTIPLE = "DELETE_PREVIRED_DATA_MULTIPLE";
export const DELETE_PREVIRED_DATA_SUCCESS = "DELETE_PREVIRED_DATA_SUCCESS";
export const DELETE_PREVIRED_DATA_FAIL = "DELETE_PREVIRED_DATA_FAIL";
export const DELETE_PREVIRED_DATA_SUCCESS_MULTIPLE =
  "DELETE_PREVIRED_DATA_SUCCESS";
export const DELETE_PREVIRED_DATA_FAIL_MULTIPLE = "DELETE_PREVIRED_DATA_FAIL";
export const ADD_PREVIRED_DATA = "ADD_PREVIRED_DATA";
export const ADD_PREVIRED_DATA_SUCCESS = "ADD_PREVIRED_DATA_SUCCESS";
export const ADD_PREVIRED_DATA_FAIL = "ADD_PREVIRED_DATA_FAIL";
export const UPDATE_PREVIRED_DATA = "UPDATE_PREVIRED_DATA";
export const UPDATE_PREVIRED_DATA_SUCCESS = "UPDATE_PREVIRED_DATA_SUCCESS";
export const UPDATE_PREVIRED_DATA_FAIL = "UPDATE_PREVIRED_DATA_FAIL";
