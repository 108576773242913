// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CUSTOMER_DATA = "GET_CUSTOMER_DATA";
export const DELETE_CUSTOMER_DATA = "DELETE_CUSTOMER_DATA";
export const DELETE_CUSTOMER_DATA_MULTIPLE = "DELETE_CUSTOMER_DATA_MULTIPLE";
export const DELETE_CUSTOMER_DATA_SUCCESS = "DELETE_CUSTOMER_DATA_SUCCESS";
export const DELETE_CUSTOMER_DATA_FAIL = "DELETE_CUSTOMER_DATA_FAIL";
export const DELETE_CUSTOMER_DATA_SUCCESS_MULTIPLE =
  "DELETE_CUSTOMER_DATA_SUCCESS";
export const DELETE_CUSTOMER_DATA_FAIL_MULTIPLE = "DELETE_CUSTOMER_DATA_FAIL";
export const ADD_CUSTOMER_DATA = "ADD_CUSTOMER_DATA";
export const ADD_CUSTOMER_DATA_SUCCESS = "ADD_CUSTOMER_DATA_SUCCESS";
export const ADD_CUSTOMER_DATA_FAIL = "ADD_CUSTOMER_DATA_FAIL";
export const UPDATE_CUSTOMER_DATA = "UPDATE_CUSTOMER_DATA";
export const UPDATE_CUSTOMER_DATA_SUCCESS = "UPDATE_CUSTOMER_DATA_SUCCESS";
export const UPDATE_CUSTOMER_DATA_FAIL = "UPDATE_CUSTOMER_DATA_FAIL";
