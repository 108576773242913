import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_PREVIRED_DATA_SUCCESS,
  DELETE_PREVIRED_DATA_FAIL,
  DELETE_PREVIRED_DATA_SUCCESS_MULTIPLE,
  DELETE_PREVIRED_DATA_FAIL_MULTIPLE,
  ADD_PREVIRED_DATA_SUCCESS,
  ADD_PREVIRED_DATA_FAIL,
  UPDATE_PREVIRED_DATA_SUCCESS,
  UPDATE_PREVIRED_DATA_FAIL,
  GET_PREVIRED_DATA_CHECK,
  GET_PREVIRED_DATA_ERRORS
} from "./actionType";

const INIT_STATE = {
  previredDataCheck: [],
  previredDataErrors: [],
  error: {}
};

const Previred = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PREVIRED_DATA_CHECK:
          return {
            ...state,
            previredDataCheck: action.payload.data,
            isPreviredCreated: false,
            isPreviredSuccess: true
          };
        case GET_PREVIRED_DATA_ERRORS:
          return {
            ...state,
            previredDataErrors: action.payload.data,
            isPreviredCreated: false,
            isPreviredSuccess: true
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PREVIRED_DATA_CHECK:
          return {
            ...state,
            error: action.payload.error,
            isPreviredCreated: false,
            isPreviredSuccess: false
          };
        case GET_PREVIRED_DATA_ERRORS:
          return {
            ...state,
            error: action.payload.error,
            isPreviredCreated: false,
            isPreviredSuccess: false
          };
        default:
          return { ...state };
      }
    case DELETE_PREVIRED_DATA_SUCCESS:
      return {
        ...state,
        previredData: state.previredData.filter(
          (previred) => previred._id.toString() !== action.payload.id.toString()
        )
      };
    case DELETE_PREVIRED_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case DELETE_PREVIRED_DATA_SUCCESS_MULTIPLE:
      return {
        ...state,
        previredData: state.previredData.filter(
          (previred) => previred._id.toString() !== action.payload.id.toString()
        )
      };
    case DELETE_PREVIRED_DATA_FAIL_MULTIPLE:
      return {
        ...state,
        error: action.payload
      };
    case ADD_PREVIRED_DATA_SUCCESS:
      return {
        ...state,
        previredData: [...state.previredData, action.payload]
      };
    case ADD_PREVIRED_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case UPDATE_PREVIRED_DATA_SUCCESS:
      return {
        ...state,
        previredData: state.previredData.map((previred) =>
          previred._id.toString() === action.payload.data._id.toString()
            ? { ...previred, ...action.payload.data }
            : previred
        )
      };
    case UPDATE_PREVIRED_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export default Previred;
