// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_USER_DATA = "GET_USER_DATA";
export const DELETE_USER_DATA = "DELETE_USER_DATA";
export const DELETE_USER_DATA_SUCCESS = "DELETE_USER_DATA_SUCCESS";
export const DELETE_USER_DATA_FAIL = "DELETE_USER_DATA_FAIL";
export const DELETE_USER_DATA_SUCCESS_MULTIPLE =
  "DELETE_USER_DATA_SUCCESS";
export const DELETE_USER_DATA_FAIL_MULTIPLE = "DELETE_USER_DATA_FAIL";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const ADD_USER_DATA_SUCCESS = "ADD_USER_DATA_SUCCESS";
export const ADD_USER_DATA_FAIL = "ADD_USER_DATA_FAIL";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAIL = "UPDATE_USER_DATA_FAIL";
