import { APIClient } from "./api_helper";
import { cliente } from "../config";
import * as url from "./url_helper";

const api = new APIClient();
// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postRegister = (data, headers) =>
  api.create(url.POST_REGISTER, data, headers);

// Login Method
export const postLogin = (data) => api.create(url.POST_LOGIN, data);

export const postProfile = (data) =>
  api.put(url.POST_EDIT_PROFILE + "/" + data.idx, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data).catch((err) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Pages > Customer
export const getCustomerData = () => api.get(url.GET_CUSTOMERDATA + "/" + cliente.CLIENTE);
export const deleteCustomerData = (customer) =>
  api.delete(url.DELETE_CUSTOMERDATA + "/" + customer._id, {
    headers: { customer }
  });
export const deleteCustomerDataMultiple = (id) =>
  api.delete(url.DELETE_CUSTOMERDATA_MULTIPLE + "/" + id);
export const addCustomerData = (customer) =>
  api.create(url.ADD_NEW_CUSTOMERDATA, customer);
export const updateCustomerData = (customer) =>
  api.put(url.UPDATE_CUSTOMERDATA + "/" + customer._id, customer);

// Pages > User
export const getUserData = () => api.get(url.GET_USERDATA, "");
export const deleteUserData = (user) =>
  api.delete(url.DELETE_USERDATA + "/" + user._id, { headers: { user } });
export const addUserData = (user) => api.create(url.ADD_NEW_USERDATA, user);
export const updateUserData = (user) =>
  api.put(url.UPDATE_USERDATA + "/" + user._id, user);


// Pages > Sii
export const getSiiData = () => api.get(url.GET_SIIDATA, "");
export const deleteSiiData = (sii) =>
  api.delete(url.DELETE_SIIDATA + "/" + sii._id, { headers: { sii } });
export const addSiiData = (sii) => api.create(url.ADD_NEW_SIIDATA, sii);
export const updateSiiData = (sii) =>
  api.put(url.UPDATE_SIIDATA + "/" + sii._id, sii);

// Pages > Previred
export const getPreviredDataCheck = () => api.get(url.GET_PREVIREDDATA_CHECK + "/" + cliente.CLIENTE);
export const getPreviredDataErrors = () => api.get(url.GET_PREVIREDDATA_ERRORS + "/" + cliente.CLIENTE);
export const deletePreviredData = (previred) =>
  api.delete(url.DELETE_PREVIREDDATA + "/" + previred._id, {
    headers: { previred }
  });
export const deletePreviredDataMultiple = (id) =>
  api.delete(url.DELETE_PREVIREDDATA_MULTIPLE + "/" + id);
export const addPreviredData = (previred) =>
  api.create(url.ADD_NEW_PREVIREDDATA, previred);
export const updatePreviredData = (customer) =>
  api.put(url.UPDATE_CUSTOMERDATA + "/" + customer._id, customer);

// File Manager

// Folder
export const getFolders = (folder) => api.get(url.GET_FOLDERS, folder);
export const deleteFolder = (folder) =>
  api.delete(url.DELETE_FOLDER, { headers: { folder } });
export const addNewFolder = (folder) => api.create(url.ADD_NEW_FOLDER, folder);
export const updateFolder = (folder) => api.put(url.UPDATE_FOLDER, folder);

// File
export const getFiles = (file) => api.get(url.GET_FILES, file);
export const deleteFile = (file) =>
  api.delete(url.DELETE_FILE, { headers: { file } });
export const addNewFile = (file) => api.create(url.ADD_NEW_FILE, file);
export const updateFile = (file) => api.put(url.UPDATE_FILE, file);

//EMAIL
export const sendEmail = (data) => api.create(url.SEND_EMAIL, data);
