import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const HtmlModal = ({ isOpen, toggle, htmlContent }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>Detalle</ModalHeader>
            <ModalBody className="center-content-htmlModal">
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    );
};

export default HtmlModal;
