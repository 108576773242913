// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_SII_DATA = "GET_SII_DATA";
export const DELETE_SII_DATA = "DELETE_SII_DATA";
export const DELETE_SII_DATA_SUCCESS = "DELETE_SII_DATA_SUCCESS";
export const DELETE_SII_DATA_FAIL = "DELETE_SII_DATA_FAIL";
export const DELETE_SII_DATA_SUCCESS_MULTIPLE =
  "DELETE_SII_DATA_SUCCESS";
export const DELETE_SII_DATA_FAIL_MULTIPLE = "DELETE_SII_DATA_FAIL";
export const ADD_SII_DATA = "ADD_SII_DATA";
export const ADD_SII_DATA_SUCCESS = "ADD_SII_DATA_SUCCESS";
export const ADD_SII_DATA_FAIL = "ADD_SII_DATA_FAIL";
export const UPDATE_SII_DATA = "UPDATE_SII_DATA";
export const UPDATE_SII_DATA_SUCCESS = "UPDATE_SII_DATA_SUCCESS";
export const UPDATE_SII_DATA_FAIL = "UPDATE_SII_DATA_FAIL";
