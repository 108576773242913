import {
  GET_USER_DATA,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_USER_DATA,
  DELETE_USER_DATA_SUCCESS,
  DELETE_USER_DATA_FAIL,
  ADD_USER_DATA,
  ADD_USER_DATA_SUCCESS,
  ADD_USER_DATA_FAIL,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAIL,
} from "./actionType";

// common success
export const userApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data }
});
// common error
export const userApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error }
});

export const getUserData = () => ({
  type: GET_USER_DATA
});

export const deleteUserData = (data) => ({
  type: DELETE_USER_DATA,
  payload: data
});

export const deleteUserDataSuccess = (data) => ({
  type: DELETE_USER_DATA_SUCCESS,
  payload: data
});

export const deleteUserDataFail = (error) => ({
  type: DELETE_USER_DATA_FAIL,
  payload: error
});


export const addUserData = (data) => ({

  type: ADD_USER_DATA,
  payload: data
});

export const addUserDataSuccess = (project) => ({
  type: ADD_USER_DATA_SUCCESS,
  payload: project
});

export const addUserDataFail = (error) => ({
  type: ADD_USER_DATA_FAIL,
  payload: error
});

export const updateUserData = (project) => ({
  type: UPDATE_USER_DATA,
  payload: project
});

export const updateUserDataSuccess = (project) => ({
  type: UPDATE_USER_DATA_SUCCESS,
  payload: project
});

export const updateUserDataFail = (error) => ({
  type: UPDATE_USER_DATA_FAIL,
  payload: error
});
