import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import TableContainer from "../../../Components/Common/TableContainer";
import HtmlModal from "../../../Components/Common/HtmlModal";
import { isEmpty } from "lodash";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
  getPreviredDataCheck as onGetPreviredData,
  deletePreviredData as onDeletePreviredData,
  updatePreviredData as onUpdatePreviredData,
} from "../../../store/actions";

import Loader from "../../../Components/Common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

const Check = (props) => {
  const [modal, setModal] = useState(false);
  const [htmlModal, setHtmlModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [activeTab, setActiveTab] = useState("1");

  const dispatch = useDispatch();
  const { previredData, isPreviredCreated, isPreviredSuccess, error } =
    useSelector((state) => ({
      previredData: state.Previred.previredDataCheck,
      isPreviredCreated: state.Previred.isPreviredCreated,
      isPreviredSuccess: state.Previred.isPreviredSuccess,
      error: state.Previred.error
    }));

  const [modalDelete, setModalDelete] = useState(1);
  const [previredList, setPreviredList] = useState([]);

  const [previred, setPrevired] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);


  const toggleModal = () => {
    setHtmlModal(!htmlModal);
  };
  const handleButtonClick = (htmlContent) => {
    setModalContent(htmlContent);
    setHtmlModal(true);
  };
  useEffect(() => {
    setPreviredList(previredData);
  }, [previredData]);

  useEffect(() => {
    if (!isEmpty(previredData)) setPreviredList(previredData);
  }, [previredData]);


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      rut: (previred && previred.rut) || "",
      name_client: (previred && previred.name_client) || "",
      archivo_previred: (previred && previred.archivo_previred) || "",
      data_previred: (previred && previred.data_previred) || "",
      status_pago: (previred && previred.status_pago) || "",
      mail_enviado: (previred && previred.mail_enviado) || "",
    },
    validationSchema: Yup.object({
      rut: Yup.string().required("Please Enter Previred Name"),
      name_client: Yup.string().required("Please Enter Previred Number"),
      archivo_previred: Yup.string().required("Please Enter Previred Time"),
      data_previred: Yup.string().required(
        "Please Enter Total billing_address"
      ),
      status_pago: Yup.string().required("Please Enter email Method"),
      mail_enviado: Yup.string().required("Please Enter Delivery phone"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatePrevired = {
          _id: previred._id,
          rut: values.rut,
          name_client: values.name_client,
          archivo_previred: values.archivo_previred,
          data_previred: values.data_previred,
          status_pago: values.status_pago,
          mail_enviado: values.mail_enviado,
        };
        // update previred
        dispatch(onUpdatePreviredData(updatePrevired));
        validation.resetForm();
      } else {
        const newPrevired = {
          _id: previred._id,
          rut: values["rut"],
          name_client: values["name_client"],
          archivo_previred: values["archivo_previred"],
          data_previred: values["data_previred"],
          status_pago: values["status_pago"],
          mail_enviado: values["mail_enviado"],
        };
        // save new previred
        //dispatch(onAddPreviredData(newPrevired));
        // dispatch(onSendEmail({ to: newPrevired.email }));
        validation.resetForm();
      }
      toggle();
    }
  });

  useEffect(() => {
    dispatch(onGetPreviredData());
  }, [dispatch]);

  useEffect(() => {
    setPrevired(previredData);
  }, [previredData]);

  useEffect(() => {
    if (!isEmpty(previredData)) {
      setPrevired(previredData);
      setIsEdit(false);
    }
  }, [previredData]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setPrevired(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const handlePreviredClicks = () => {
    setPrevired("");
    setIsEdit(false);
    toggle();
  };

  const handlePreviredClick = useCallback(
    (arg) => {
      const previred = arg;
      setPrevired({
        _id: previred._id,
        rut: previred.rut,
        name_client: previred.name_client,
        archivo_previred: previred.archivo_previred,
        data_previred: previred.data_previred,
        status_pago: previred.status_pago,
        mail_enviado: previred.mail_enviado,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // // Node API
  useEffect(() => {
    if (isPreviredCreated) {
      setPrevired(null);
      dispatch(onGetPreviredData());
    }
  }, [dispatch, isPreviredCreated]);

  // Checked All
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".previredCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="previredCheckBox form-check-input"
              value={cellProps.row.original._id}
            />
          );
        },
        id: "#"
      },
      {
        Header: "RUT",
        accessor: "rut",
        Cell: ({ row }) => {
          return `${row.original.rut}`;
        },
        filterable: true
      },
      {
        Header: "Nombre Cliente",
        accessor: "name_client",
        filterable: false
      },
      {
        Header: "Email",
        accessor: "mail",
        filterable: false
      },
      {
        Header: "User Previred",
        accessor: "user_previred",
        filterable: false
      },
      {
        Header: "Cliente",
        accessor: "cliente",
        filterable: false
      },
      {
        Header: "Archivo Previred",
        accessor: "archivo_previred",
      },
      {
        Header: "Fonasa",
        accessor: "FONASA",
        filterable: false
      },
      {
        Header: "ISL",
        accessor: "Instituto de Seguridad Laboral (ISL)",
        filterable: false
      },
      {
        Header: "MODELO",
        accessor: "Modelo",
        filterable: false
      },
      {
        Header: "Plan Vital",
        accessor: "PlanVital",
        filterable: false
      },
      {
        Header: "UNO",
        accessor: "UNO",
        filterable: false
      },
      {
        Header: "Status Pago",
        accessor: "status_pago",
        filterable: false
      },
      {
        Header: "Correo Enviado",
        accessor: "mail_enviado"
      },
      {
        Header: "Detalle",
        accessor: "data_html",
        Cell: ({ value }) => (
          value && value.trim() !== "" ? (
            <Link to="#" onClick={() => handleButtonClick(value)}>
              <i className=" ri-eye-fill" style={{ color: 'red', fontSize: '25px' }} title="Ver Detalle"></i>
            </Link>
          ) : (

            <i className=" ri-eye-fill" style={{ color: '#ccc', fontSize: '25px' }} title="Ver Detalle"></i>

          )
        ),
      },
      {
        Header: "Acción",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const previredData = cellProps.row.original;
                    //handlePreviredClick(previredData);
                  }}>
                  <i className=" ri-inbox-archive-fill" style={{ color: 'red', fontSize: '25px' }} title="Descargar archivo previred"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const previredData = cellProps.row.original;
                    //onClickDelete(previredData);
                  }}>
                  <i className="ri-inbox-unarchive-fill" style={{ color: 'green', fontSize: '25px' }} title="Subir archivo previred"></i>
                </Link>
              </li>
            </ul>
          );
        }
      }
    ],
    [handlePreviredClick]
  );

  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
      ];
    let h = d.getHours() % 12 || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      ", " +
      h +
      ":" +
      d.getMinutes() +
      " " +
      ampm
    ).toString();
  };

  const [date, setDate] = useState(defaultdate());

  const setTearModal = () => {
    setDeleteModal(true);
    setModalDelete(2);
  };
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = `Previred | ${process.env.REACT_APP_TITLE_PAGE}`;
  return (
    <div className="page-content">
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={previredList}
      />

      <HtmlModal isOpen={htmlModal} toggle={toggleModal} htmlContent={modalContent} />


      <Container fluid>
        {/* <BreadCrumb title="Clientes" pageTitle="Inicio" /> */}
        <Row>
          <Col lg={12}>
            <Card id="previredList">
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Lista de Clientes Previred
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      id="create-btn"
                    >
                      Descargar Archivo Previred por Lote
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-success"
                    >
                      Subir Archivo Previred por Lote
                    </button>{" "}
                    <button
                      className="btn btn-info"
                      onClick={() => setIsExportCSV(true)}>
                      Descargar CSV
                    </button>
                  </div>
                </div>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  {isPreviredSuccess && previredList.length ? (
                    <>
                      <TableContainer
                        columns={columns}
                        data={previredList}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={8}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted text-uppercase"
                        handlePreviredClick={handlePreviredClicks}
                        isPreviredFilter={true}
                      />
                    </>
                  ) : (
                    <Loader error={"No hay Registros"} />
                  )}
                </div>
                <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                  <ModalHeader className="bg-light p-3" toggle={toggle}>
                    {!!isEdit ? "Editar Cliente" : "Agregar cliente"}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <ModalBody>
                      <input type="hidden" id="id-field" />

                      <div className="mb-3">
                        <Label htmlFor="business_name" className="form-label">
                          Razón Social
                        </Label>
                        <Input
                          name="business_name"
                          id="business_name"
                          className="form-control"
                          placeholder="Introduzca Razón Social"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.business_name || ""}
                          invalid={
                            validation.touched.business_name &&
                              validation.errors.business_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.business_name &&
                          validation.errors.business_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.business_name}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="document_number" className="form-label">
                          Rut
                        </Label>
                        <Input
                          name="document_number"
                          id="document_number"
                          className="form-control"
                          placeholder="Introduzca Rut"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.document_number || ""}
                          invalid={
                            validation.touched.document_number &&
                              validation.errors.document_number
                              ? true
                              : false
                          }
                        />
                        {validation.touched.document_number &&
                          validation.errors.document_number ? (
                          <FormFeedback type="invalid">
                            {validation.errors.document_number}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label
                          htmlFor="comercial_activity"
                          className="form-label">
                          Giro
                        </Label>
                        <Input
                          name="comercial_activity"
                          id="comercial_activity"
                          className="form-control"
                          placeholder="Introduzca Giro"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.comercial_activity || ""}
                          invalid={
                            validation.touched.comercial_activity &&
                              validation.errors.comercial_activity
                              ? true
                              : false
                          }
                        />
                        {validation.touched.comercial_activity &&
                          validation.errors.comercial_activity ? (
                          <FormFeedback type="invalid">
                            {validation.errors.comercial_activity}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="billing_address" className="form-label">
                          Dirección Facturación
                        </Label>
                        <Input
                          name="billing_address"
                          id="billing_address"
                          className="form-control"
                          placeholder="Introduzca  Dirección Facturación"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.billing_address || ""}
                          invalid={
                            validation.touched.billing_address &&
                              validation.errors.billing_address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.billing_address &&
                          validation.errors.billing_address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.billing_address}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          Email
                        </Label>
                        <Input
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Introduzca Email"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="phone" className="form-label">
                          Teléfono
                        </Label>
                        <Input
                          name="phone"
                          id="phone"
                          className="form-control"
                          placeholder="Introduzca Teléfono"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="manager" className="form-label">
                          Responsable
                        </Label>
                        <Input
                          name="manager"
                          id="manager"
                          className="form-control"
                          placeholder="Introduzca  Responsable"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.manager || ""}
                          invalid={
                            validation.touched.manager &&
                              validation.errors.manager
                              ? true
                              : false
                          }
                        />
                        {validation.touched.manager &&
                          validation.errors.manager ? (
                          <FormFeedback type="invalid">
                            {validation.errors.manager}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModal(false);
                          }}>
                          Close
                        </button>

                        <button type="submit" className="btn btn-success">
                          {!!isEdit ? "Actualizar" : "Agregar"}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Check;
