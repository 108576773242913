import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isCustomers, setIsCustomers] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPrevired, setIsPrevired] = useState(false);
  const [isSII, setIsSII] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Customers") {
      setIsCustomers(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Previred") {
      setIsPrevired(false);
    }
    if (iscurrentState !== "S.I.I.") {
      setIsSII(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isAuth,
    isPrevired,
    isSII
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isDashboard
    },
    {
      id: "customers",
      label: "Clientes",
      icon: "ri-apps-2-line",
      link: "/customers",
      stateVariables: isCustomers
    },
    {
      id: "previred",
      label: "Previred",
      icon: "ri-apps-2-line",
      link: "/previred",
      stateVariables: isPrevired,
      click: function (e) {
        e.preventDefault();
        setIsPrevired(!isPrevired);
        setIscurrentState('Previred');
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "check",
          label: "Revision",
          link: "/previred/check",
          parentId: "previred",
        },
        {
          id: "errors",
          label: "Errores",
          link: "/previred/error",
          parentId: "previred",
        },
        {
          id: "summary",
          label: "Resumen",
          link: "/summary",
          parentId: "previred",
        }
      ]
    },
    {
      id: "sii",
      label: "Servicio de Impuesto Interno",
      icon: "ri-apps-2-line",
      link: "/processes",
      stateVariables: isSII,

    }
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
