import {
  GET_SII_DATA,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_SII_DATA_SUCCESS,
  DELETE_SII_DATA_FAIL,
  ADD_SII_DATA_SUCCESS,
  ADD_SII_DATA_FAIL,
  UPDATE_SII_DATA_SUCCESS,
  UPDATE_SII_DATA_FAIL
} from "./actionType";

const INIT_STATE = {
  siiData: [],
  error: {}
};

const Sii = (state = INIT_STATE, action) => {

  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SII_DATA:
          return {
            ...state,
            siiData: action.payload.data,
            isSiiCreated: false,
            isSiiSuccess: true
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_SII_DATA:
          return {
            ...state,
            error: action.payload.error,
            isSiiCreated: false,
            isSiiSuccess: false
          };
        default:
          return { ...state };
      }
    case DELETE_SII_DATA_SUCCESS:
      return {
        ...state,
        siiData: state.siiData.filter(
          (sii) => sii._id.toString() !== action.payload.id.toString()
        )
      };
    case DELETE_SII_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case ADD_SII_DATA_SUCCESS:
      return {
        ...state,
        siiData: [...state.siiData, action.payload]
      };
    case ADD_SII_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case UPDATE_SII_DATA_SUCCESS:
      return {
        ...state,
        siiData: state.siiData.map((sii) =>
          sii._id.toString() === action.payload.data._id.toString()
            ? { ...sii, ...action.payload.data }
            : sii
        )
      };
    case UPDATE_SII_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export default Sii;
