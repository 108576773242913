module.exports = {
  api: {
    API_URL: "https://zlaq8vuc54.execute-api.us-east-2.amazonaws.com/Stage",
  },
  cliente: {
    CLIENTE: "77215347-3",
  }
};



// https://zlaq8vuc54.execute-api.us-east-2.amazonaws.com/Stage/cliente/77215347-3
// https://zlaq8vuc54.execute-api.us-east-2.amazonaws.com/Stage/cliente/77215347-3