import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CSVLink } from "react-csv";

const ExportCSVModal = ({ show, onCloseClick, data }) => {
  const excludedColumn = 'data_html';
  const filteredData = data.map(({ [excludedColumn]: _, ...rest }) => rest);
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader toggle={onCloseClick}></ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/nocovwne.json"
            trigger="loop"
            colors="primary:#0ab39c,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          >
          </lord-icon>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Estas Seguro ?</h4>
            <p className="text-muted mx-4 mb-0">
              Quieres exportar Datos a archivo csv?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            Cerrar
          </button>
          <CSVLink
            data={filteredData}
            type="button"
            onClick={onCloseClick}
            className="btn w-sm btn-success "
            id="delete-record"
          >
            Descargar
          </CSVLink>
        </div>
      </ModalBody>
    </Modal>
  );
};

ExportCSVModal.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.any,
  show: PropTypes.any,
};

export default ExportCSVModal;