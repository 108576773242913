import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import TableContainer from "../../Components/Common/TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
  getCustomerData as onGetCustomerData,
  deleteCustomerData as onDeleteCustomerData,
  deleteCustomerDataMultiple as onDeleteCustomerDataMultiple,
  addCustomerData as onAddCustomerData,
  updateCustomerData as onUpdateCustomerData,
  // sendEmail as onSendEmail
} from "../../store/actions";

import Loader from "../../Components/Common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const Customers = (props) => {
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const dispatch = useDispatch();
  const { customerData, isCustomerCreated, isCustomerSuccess, error } =
    useSelector((state) => ({
      customerData: state.Customers.customerData,
      isCustomerCreated: state.Customers.isCustomerCreated,
      isCustomerSuccess: state.Customers.isCustomerSuccess,
      error: state.Customers.error
    }));

  const [modalDelete, setModalDelete] = useState(1);
  const [customerList, setCustomerList] = useState([]);

  const [customer, setCustomer] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (customer) => {

    setModalDelete(1);
    setCustomer(customer);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = () => {
    if (customer) {
      dispatch(onDeleteCustomerData(customer));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setCustomerList(customerData);
  }, [customerData]);

  useEffect(() => {
    if (!isEmpty(customerData)) setCustomerList(customerData);
  }, [customerData]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredCustomers = customerData;
      if (type !== "all") {
        filteredCustomers = customerData.filter(
          (customer) => customer.phone === type
        );
      }
      setCustomerList(filteredCustomers);
    }
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      business_name: (customer && customer.business_name) || "",
      document_number: (customer && customer.document_number) || "",
      comercial_activity: (customer && customer.comercial_activity) || "",
      billing_address: (customer && customer.billing_address) || "",
      email: (customer && customer.email) || "",
      phone: (customer && customer.phone) || "",
      manager: (customer && customer.manager) || ""
    },
    validationSchema: Yup.object({
      business_name: Yup.string().required("Please Enter Customer Name"),
      document_number: Yup.string().required("Please Enter Customer Number"),
      // createdAt: Yup.string().required("Please Enter Customer Date"),
      comercial_activity: Yup.string().required("Please Enter Customer Time"),
      billing_address: Yup.string().required(
        "Please Enter Total billing_address"
      ),
      email: Yup.string().required("Please Enter email Method"),
      phone: Yup.string().required("Please Enter Delivery phone"),
      manager: Yup.string().required("Please Enter Delivery manager")
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCustomer = {
          _id: customer._id,
          business_name: values.business_name,
          document_number: values.document_number,
          comercial_activity: values.comercial_activity,
          billing_address: values.billing_address,
          email: values.email,
          phone: values.phone,

          manager: values.manager
        };
        // update customer
        dispatch(onUpdateCustomerData(updateCustomer));
        validation.resetForm();
      } else {
        const newCustomer = {
          _id: customer._id,
          business_name: values["business_name"],
          document_number: values["document_number"],
          comercial_activity: values["comercial_activity"],
          billing_address: values["billing_address"],
          email: values["email"],
          phone: values["phone"],

          manager: values["manager"]
        };
        // save new customer
        dispatch(onAddCustomerData(newCustomer));
        // dispatch(onSendEmail({ to: newCustomer.email }));
        validation.resetForm();
      }
      toggle();
    }
  });

  useEffect(() => {
    dispatch(onGetCustomerData());
  }, [dispatch]);

  useEffect(() => {
    setCustomer(customerData);
  }, [customerData]);

  useEffect(() => {
    if (!isEmpty(customerData)) {
      setCustomer(customerData);
      setIsEdit(false);
    }
  }, [customerData]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCustomer(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const handleCustomerClicks = () => {
    setCustomer("");
    setIsEdit(false);
    toggle();
  };

  const handleCustomerClick = useCallback(
    (arg) => {
      const customer = arg;
      setCustomer({
        _id: customer._id,
        business_name: customer.business_name,
        document_number: customer.document_number,
        comercial_activity: customer.comercial_activity,
        billing_address: customer.billing_address,
        email: customer.email,
        phone: customer.phone,
        manager: customer.manager
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // // Node API
  useEffect(() => {
    if (isCustomerCreated) {
      setCustomer(null);
      dispatch(onGetCustomerData());
    }
  }, [dispatch, isCustomerCreated]);

  // Checked All
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".customerCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".customerCheckBox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteCustomerDataMultiple(element.value));
    });
    setDeleteModal(false);
    checkall.checked = false;
  };

  const formatMoney = (value) => {
    if (value) {
      return `$${value.toFixed(2)}`;
    } else {
      return "-";
    }
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="customerCheckBox form-check-input"
              value={cellProps.row.original._id}
            />
          );
        },
        id: "#"
      },
      {
        Header: "RUT",
        accessor: "rut",
        Cell: ({ row }) => {
          return `${row.original.rut}`;
        },
        filterable: true
      },
      {
        Header: "SEND MAIL SII",
        accessor: "send_mail_sii",
        filterable: false
      },
      {
        Header: "ACTIVO",
        accessor: "activo",
      },
      {
        Header: "ACTIVO PREVIRED",
        accessor: "activo_previred",
        filterable: false
      },
      {
        Header: "NOMBRE CLIENTE",
        accessor: "name_client",
        filterable: false
      },
      {
        Header: "STATUS CLIENTE",
        accessor: "status_client"
      },

      {
        Header: "Acción",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                  }}>
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickDelete(customerData);
                  }}>
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        }
      }
    ],
    [handleCustomerClick]
  );

  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
      ];
    let h = d.getHours() % 12 || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      ", " +
      h +
      ":" +
      d.getMinutes() +
      " " +
      ampm
    ).toString();
  };

  const [date, setDate] = useState(defaultdate());

  const dateformate = (e) => {
    const dateString = e.toString().split(" ");

    let time = dateString[4];
    let H = +time.substr(0, 2);
    let h = H % 12 || 12;
    h = h <= 9 ? (h = "0" + h) : h;
    let ampm = H < 12 ? "AM" : "PM";
    time = h + time.substr(2, 3) + " " + ampm;

    const date = dateString[2] + " " + dateString[1] + ", " + dateString[3];
    const createdAt = (date + ", " + time).toString();
    setDate(createdAt);
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };
  const setTearModal = () => {
    setDeleteModal(true);
    setModalDelete(2);
  };
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = `Customers | ${process.env.REACT_APP_TITLE_PAGE}`;
  return (
    <div className="page-content">
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={customerList}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={
          modalDelete === 1 ? handleDeleteCustomer : deleteMultiple
        }
        onCloseClick={() => setDeleteModal(false)}
      />

      <Container fluid>
        {/* <BreadCrumb title="Clientes" pageTitle="Inicio" /> */}
        <Row>
          <Col lg={12}>
            <Card id="customerList">
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Lista de Clientes
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      id="create-btn"
                      onClick={() => {
                        setIsEdit(false);
                        toggle();
                      }}>
                      <i className="ri-add-line align-bottom me-1"></i> Crear
                      Cliente
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                      Exportar
                    </button>{" "}
                    <button
                      className="btn btn-danger"
                      onClick={() => setTearModal()}>
                      <i className="ri-delete-bin-2-line"></i>
                    </button>
                  </div>
                </div>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  {isCustomerSuccess && customerList.length ? (
                    <>
                      <TableContainer
                        columns={columns}
                        data={customerList}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={8}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted text-uppercase"
                        handleCustomerClick={handleCustomerClicks}
                        isCustomerFilter={true}
                      />
                    </>
                  ) : (
                    <Loader error={"No hay Registros"} />
                  )}
                </div>
                <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                  <ModalHeader className="bg-light p-3" toggle={toggle}>
                    {!!isEdit ? "Editar Cliente" : "Agregar cliente"}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <ModalBody>
                      <input type="hidden" id="id-field" />

                      <div className="mb-3">
                        <Label htmlFor="business_name" className="form-label">
                          Razón Social
                        </Label>
                        <Input
                          name="business_name"
                          id="business_name"
                          className="form-control"
                          placeholder="Introduzca Razón Social"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.business_name || ""}
                          invalid={
                            validation.touched.business_name &&
                            validation.errors.business_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.business_name &&
                        validation.errors.business_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.business_name}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="document_number" className="form-label">
                          Rut
                        </Label>
                        <Input
                          name="document_number"
                          id="document_number"
                          className="form-control"
                          placeholder="Introduzca Rut"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.document_number || ""}
                          invalid={
                            validation.touched.document_number &&
                            validation.errors.document_number
                              ? true
                              : false
                          }
                        />
                        {validation.touched.document_number &&
                        validation.errors.document_number ? (
                          <FormFeedback type="invalid">
                            {validation.errors.document_number}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label
                          htmlFor="comercial_activity"
                          className="form-label">
                          Giro
                        </Label>
                        <Input
                          name="comercial_activity"
                          id="comercial_activity"
                          className="form-control"
                          placeholder="Introduzca Giro"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.comercial_activity || ""}
                          invalid={
                            validation.touched.comercial_activity &&
                            validation.errors.comercial_activity
                              ? true
                              : false
                          }
                        />
                        {validation.touched.comercial_activity &&
                        validation.errors.comercial_activity ? (
                          <FormFeedback type="invalid">
                            {validation.errors.comercial_activity}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="billing_address" className="form-label">
                          Dirección Facturación
                        </Label>
                        <Input
                          name="billing_address"
                          id="billing_address"
                          className="form-control"
                          placeholder="Introduzca  Dirección Facturación"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.billing_address || ""}
                          invalid={
                            validation.touched.billing_address &&
                            validation.errors.billing_address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.billing_address &&
                        validation.errors.billing_address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.billing_address}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          Email
                        </Label>
                        <Input
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Introduzca Email"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="phone" className="form-label">
                          Teléfono
                        </Label>
                        <Input
                          name="phone"
                          id="phone"
                          className="form-control"
                          placeholder="Introduzca Teléfono"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="manager" className="form-label">
                          Responsable
                        </Label>
                        <Input
                          name="manager"
                          id="manager"
                          className="form-control"
                          placeholder="Introduzca  Responsable"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.manager || ""}
                          invalid={
                            validation.touched.manager &&
                            validation.errors.manager
                              ? true
                              : false
                          }
                        />
                        {validation.touched.manager &&
                        validation.errors.manager ? (
                          <FormFeedback type="invalid">
                            {validation.errors.manager}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModal(false);
                          }}>
                          Close
                        </button>

                        <button type="submit" className="btn btn-success">
                          {!!isEdit ? "Actualizar" : "Agregar"}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Customers;
