import {
  GET_CUSTOMER_DATA,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_CUSTOMER_DATA,
  DELETE_CUSTOMER_DATA_SUCCESS,
  DELETE_CUSTOMER_DATA_FAIL,
  DELETE_CUSTOMER_DATA_SUCCESS_MULTIPLE,
  DELETE_CUSTOMER_DATA_FAIL_MULTIPLE,
  ADD_CUSTOMER_DATA,
  ADD_CUSTOMER_DATA_SUCCESS,
  ADD_CUSTOMER_DATA_FAIL,
  UPDATE_CUSTOMER_DATA,
  UPDATE_CUSTOMER_DATA_SUCCESS,
  UPDATE_CUSTOMER_DATA_FAIL,
  DELETE_CUSTOMER_DATA_MULTIPLE
} from "./actionType";

// common success
export const customerApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data }
});
// common error
export const customerApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error }
});

export const getCustomerData = () => ({
  type: GET_CUSTOMER_DATA
});

export const deleteCustomerData = (data) => ({
  type: DELETE_CUSTOMER_DATA,
  payload: data
});
export const deleteCustomerDataMultiple = (data) => ({
  type: DELETE_CUSTOMER_DATA_MULTIPLE,
  payload: data
});

export const deleteCustomerDataSuccess = (data) => ({
  type: DELETE_CUSTOMER_DATA_SUCCESS,
  payload: data
});

export const deleteCustomerDataFail = (error) => ({
  type: DELETE_CUSTOMER_DATA_FAIL,
  payload: error
});
export const deleteCustomerDataSuccessMultiple = (data) => ({
  type: DELETE_CUSTOMER_DATA_SUCCESS_MULTIPLE,
  payload: data
});

export const deleteCustomerDataFailMultiple = (error) => ({
  type: DELETE_CUSTOMER_DATA_FAIL_MULTIPLE,
  payload: error
});

export const addCustomerData = (data) => ({
  type: ADD_CUSTOMER_DATA,
  payload: data
});

export const addCustomerDataSuccess = (project) => ({
  type: ADD_CUSTOMER_DATA_SUCCESS,
  payload: project
});

export const addCustomerDataFail = (error) => ({
  type: ADD_CUSTOMER_DATA_FAIL,
  payload: error
});

export const updateCustomerData = (project) => ({
  type: UPDATE_CUSTOMER_DATA,
  payload: project
});

export const updateCustomerDataSuccess = (project) => ({
  type: UPDATE_CUSTOMER_DATA_SUCCESS,
  payload: project
});

export const updateCustomerDataFail = (error) => ({
  type: UPDATE_CUSTOMER_DATA_FAIL,
  payload: error
});
