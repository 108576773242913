import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Crypto Redux States
import {
  GET_USER_DATA,
  ADD_USER_DATA,
  DELETE_USER_DATA,

  UPDATE_USER_DATA
} from "./actionType";
import {
  userApiResponseSuccess,
  userApiResponseError,
  addUserDataSuccess,
  addUserDataFail,
  updateUserDataSuccess,
  updateUserDataFail,
  deleteUserDataSuccess,
  deleteUserDataFail,

} from "./action";

//Include Both Helper File with needed methods
import {
  getUserData as getUserDataApi,
  addUserData,
  updateUserData,
  deleteUserData,

} from "../../helpers/backend_helper";

function* getUserData() {
  try {
    const response = yield call(getUserDataApi);
    yield put(userApiResponseSuccess(GET_USER_DATA, response));
  } catch (error) {
    yield put(userApiResponseError(GET_USER_DATA, error));
  }
}

function* onAddUserData({ payload: data }) {
  try {
    const response = yield call(addUserData, data);
    yield put(addUserDataSuccess(response));
    toast.success("Usuario Agregado satisfactoriamente", { autoClose: 3000 });
  } catch (error) {
    yield put(addUserDataFail(error));
    toast.error("Error al agregar usuario", { autoClose: 3000 });
  }
}

function* onUpdateUserData({ payload: data }) {
  try {
    const response = yield call(updateUserData, data);
    yield put(updateUserDataSuccess(response));
    toast.success("Usuario actualizado satisfactoriamente", { autoClose: 3000 });
  } catch (error) {
    yield put(updateUserDataFail(error));
    toast.error("Error al Actualizar usuario", { autoClose: 3000 });
  }
}

function* onDeleteUserData({ payload: data }) {
  try {
    const response = yield call(deleteUserData, data);
    yield put(deleteUserDataSuccess(response));
    toast.success("Usuario Eliminado satisfactoriamente", {
      autoClose: 3000
    });
  } catch (error) {
    yield put(deleteUserDataFail(error));
    toast.error("Error al Eliminar usuario", { autoClose: 3000 });
  }
}

export function* watchGetUserData() {
  yield takeEvery(GET_USER_DATA, getUserData);
}

export function* watchAddUserData() {
  yield takeEvery(ADD_USER_DATA, onAddUserData);
}

export function* watchUpdateUserData() {
  yield takeEvery(UPDATE_USER_DATA, onUpdateUserData);
}

export function* watchDeleteUserData() {
  yield takeEvery(DELETE_USER_DATA, onDeleteUserData);
}


function* userSaga() {
  yield all([
    fork(watchGetUserData),
    fork(watchAddUserData),
    fork(watchUpdateUserData),
    fork(watchDeleteUserData),
  ]);
}

export default userSaga;
