import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

import logoActiveOn from "../../assets/images/png/logo-sm.png";
import logoActiveOff from "../../assets/images/png/logo-sm-off.png";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
  getUserData as onGetUserData,
  deleteUserData as onDeleteUserData,
  // deleteUserDataMultiple as onDeleteUserDataMultiple,
  addUserData as onAddUserData,
  updateUserData as onUpdateUserData
} from "../../store/actions";

import Loader from "../../Components/Common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const Users = (props) => {
  document.title = `Users | ${process.env.REACT_APP_TITLE_PAGE}`;

  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const dispatch = useDispatch();
  const [userActiveIsChecked, setUserActiveIsChecked] = useState(false);
  const { userData, isUserCreated, isUserSuccess, error } = useSelector(
    (state) => ({
      userData: state.Users.userData,
      isUserCreated: state.Users.isUserCreated,
      isUserSuccess: state.Users.isUserSuccess,
      error: state.Users.error
    })
  );

  const [modalDelete, setModalDelete] = useState(1);
  const [userList, setUserList] = useState([]);

  const [user, setUser] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (user) => {
    setModalDelete(1);
    setUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (user) {
      dispatch(onDeleteUserData(user));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setUserList(userData);
  }, [userData]);

  useEffect(() => {
    if (!isEmpty(userData)) setUserList(userData);
  }, [userData]);

  const handleChangeActiveUser = () => {
    setUserActiveIsChecked(!userActiveIsChecked);
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredUsers = userData;
      if (type !== "all") {
        filteredUsers = userData.filter((user) => user.phone === type);
      }
      setUserList(filteredUsers);
    }
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: (user && user.first_name) || "",
      last_name: (user && user.last_name) || "",
      email: (user && user.email) || "",
      password: (user && user.password) || "",
      role: (user && user.role) || "",
      active: (user && user.active) || ""
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Por favor introduzca su nombre"),
      last_name: Yup.string().required("Por favor introduzca su apellido"),
      email: Yup.string().required("Por favor introduzca su email"),
      password: Yup.string().required("Por favor introduzca su password"),
      role: Yup.string().required("Por favor introduzca su role"),
      active: Yup.boolean()
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          _id: user._id,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          role: values.role,
          active: userActiveIsChecked
        };
        // save edit User data
        dispatch(onUpdateUserData(updateUser));
        validation.resetForm();
      } else {
        const newUser = {
          _id: user._id,
          // id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          first_name: values["first_name"],
          last_name: values["last_name"],
          email: values["email"],
          password: values["password"],
          role: values["role"],
          active: userActiveIsChecked
        };
        // save new UserData

        dispatch(onAddUserData(newUser));
        validation.resetForm();
      }
      toggle();
    }
  });

  useEffect(() => {
    dispatch(onGetUserData());
  }, [dispatch]);

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  useEffect(() => {
    if (!isEmpty(userData)) {
      setUser(userData);
      setIsEdit(false);
    }
  }, [userData]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setUser(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const handleUserClicks = () => {
    setUser("");
    setIsEdit(false);
    toggle();
  };

  const handleUserClick = useCallback(
    (arg) => {
      const user = arg;
      setUser({
        _id: user._id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        password: user.password,
        role: user.role,
        active: user.active
      });
      setUserActiveIsChecked(user.active);
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // // Node API
  useEffect(() => {
    if (isUserCreated) {
      setUser(null);
      dispatch(onGetUserData());
    }
  }, [dispatch, isUserCreated]);

  // Checked All
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".userCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const formatMoney = (value) => {
    if (value) {
      return `$${value.toFixed(2)}`;
    } else {
      return "-";
    }
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="userCheckBox form-check-input"
              value={cellProps.row.original._id}
            />
          );
        },
        id: "#"
      },
      {
        Header: "Nombre",
        accessor: "first_name",
        Cell: ({ row }) => {
          return `${row.original.first_name}`;
        },
        filterable: true
      },
      {
        Header: "Apellido",
        accessor: "last_name",
        filterable: false
      },
      {
        Header: "Fecha Registro",
        accessor: "createdAt",

        Cell: (user) => (
          <>
            {handleValidDate(user.row.original.createdAt)},
            <small className="text-muted">
              {" "}
              {handleValidTime(user.row.original.createdAt)}
            </small>
          </>
        )
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false
      },
      {
        Header: "Role",
        accessor: "role",
        filterable: false
      },
      {
        Header: "Estado",
        accessor: "active",
        Cell: (user) => {
          return (
            <li style={{ listStyle: "none" }}>
              {user.row.original.active ? (
                <img
                  src={logoActiveOn}
                  alt="user-active"
                  height="30px"
                  data-bs-toggle="tooltip"
                  title="Usuario Activado"
                />
              ) : (
                <img
                  src={logoActiveOff}
                  alt="user-inactive"
                  height="30px"
                  height="30px"
                  data-bs-toggle="tooltip"
                  title="Usuario Inactivo"
                />
              )}
            </li>
          );
        }
      },

      {
        Header: "Acción",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}>
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}>
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        }
      }
    ],
    [handleUserClick]
  );

  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
      ];
    let h = d.getHours() % 12 || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      ", " +
      h +
      ":" +
      d.getMinutes() +
      " " +
      ampm
    ).toString();
  };

  const [date, setDate] = useState(defaultdate());

  const dateformate = (e) => {
    const dateString = e.toString().split(" ");

    let time = dateString[4];
    let H = +time.substr(0, 2);
    let h = H % 12 || 12;
    h = h <= 9 ? (h = "0" + h) : h;
    let ampm = H < 12 ? "AM" : "PM";
    time = h + time.substr(2, 3) + " " + ampm;

    const date = dateString[2] + " " + dateString[1] + ", " + dateString[3];
    const createdAt = (date + ", " + time).toString();
    setDate(createdAt);
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };
  const setTearModal = () => {
    setDeleteModal(true);
    setModalDelete(2);
  };
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = `Users | ${process.env.REACT_APP_TITLE_PAGE}`;
  return (
    <div className="page-content">
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={userList}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Container fluid>
        {/* <BreadCrumb title="Clientes" pageTitle="Inicio" /> */}
        <Row>
          <Col lg={12}>
            <Card id="userList">
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Lista de Usuario
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      id="create-btn"
                      onClick={() => {
                        setIsEdit(false);
                        toggle();
                      }}>
                      <i className="ri-add-line align-bottom me-1"></i> Crear
                      Usuario
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                      Exportar
                    </button>{" "}
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => setTearModal()}>
                      <i className="ri-delete-bin-2-line"></i>
                    </button> */}
                  </div>
                </div>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  {isUserSuccess && userList.length ? (
                    <>
                      <TableContainer
                        columns={columns}
                        data={userList}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={8}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted text-uppercase"
                        handleUserClick={handleUserClicks}
                        isUserFilter={true}
                      />
                    </>
                  ) : (
                    <Loader error={"No hay Registros"} />
                  )}
                </div>
                <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                  <ModalHeader className="bg-light p-3" toggle={toggle}>
                    {!!isEdit ? "Editar Cliente" : "Agregar cliente"}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <ModalBody>
                      {/* <input type="hidden" id="id-field" /> */}

                      <div className="mb-3">
                        <Label htmlFor="first_name" className="form-label">
                          Nombre
                        </Label>
                        <Input
                          name="first_name"
                          id="first_name"
                          className="form-control"
                          placeholder="Introduzca Nombre"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                            validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.first_name &&
                        validation.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="document_number" className="form-label">
                          Apellido
                        </Label>
                        <Input
                          name="last_name"
                          id="last_name"
                          className="form-control"
                          placeholder="Introduzca Apellido"
                          type="text"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                            validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.last_name &&
                        validation.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          Email
                        </Label>
                        <Input
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Introduzca Email"
                          type="email"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="password" className="form-label">
                          Password
                        </Label>
                        <Input
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder="Introduzca Password"
                          type="password"
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="role" className="form-label">
                          Role
                        </Label>
                        <select
                          type="text"
                          className="form-select"
                          id="role"
                          placeholder="Seleccione un Role"
                          name="role"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.role || "default"}>
                          <option>Seleccione un Role</option>
                          <option defaultValue="1">Admin</option>
                          <option defaultValue="2">User</option>
                        </select>
                        {validation.touched.role && validation.errors.role ? (
                          <FormFeedback type="invalid">
                            {validation.errors.role}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-check form-switch form-switch-lg">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="active"
                          name="active"
                          value={validation.values.active || ""}
                          onChange={handleChangeActiveUser}
                          onBlur={validation.handleBlur}
                          checked={userActiveIsChecked}
                          invalid={
                            validation.touched.active &&
                            validation.errors.active
                              ? true
                              : false
                          }
                        />
                        <Label className="form-check-label" for="active">
                          User Activo
                        </Label>
                        {validation.touched.active &&
                        validation.errors.active ? (
                          <FormFeedback type="invalid">
                            {validation.errors.active}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModal(false);
                          }}>
                          Close
                        </button>

                        <button type="submit" className="btn btn-success">
                          {!!isEdit ? "Actualizar" : "Agregar"}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Users;
