import {
  GET_USER_DATA,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_USER_DATA_SUCCESS,
  DELETE_USER_DATA_FAIL,
  ADD_USER_DATA_SUCCESS,
  ADD_USER_DATA_FAIL,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAIL
} from "./actionType";

const INIT_STATE = {
  userData: [],
  error: {}
};

const User = (state = INIT_STATE, action) => {

  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_USER_DATA:
          return {
            ...state,
            userData: action.payload.data,
            isUserCreated: false,
            isUserSuccess: true
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_USER_DATA:
          return {
            ...state,
            error: action.payload.error,
            isUserCreated: false,
            isUserSuccess: false
          };
        default:
          return { ...state };
      }
    case DELETE_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: state.userData.filter(
          (user) => user._id.toString() !== action.payload.id.toString()
        )
      };
    case DELETE_USER_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case ADD_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: [...state.userData, action.payload]
      };
    case ADD_USER_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: state.userData.map((user) =>
          user._id.toString() === action.payload.data._id.toString()
            ? { ...user, ...action.payload.data }
            : user
        )
      };
    case UPDATE_USER_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export default User;
